<template>
    <v-card class="cont-table">
    <v-card-title>
      <h3>Link Gaps Analysis</h3>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>

    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="url"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.id`]="{ index }">
        {{ Number(index+1).toFixed(0) }}
      </template>
      <template v-slot:[`item.url`]="{ item }">
        <div class="col-domain">
          <a :href="item.url" target="_blank">{{ item.url }}</a>
        </div>
      </template>
      <template v-slot:[`item.backlinks`]="{ item }">
        {{ Number(item.count).toFixed(0) }}
      </template>

    </v-data-table>
  </v-card>

</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
  },
  data() {
    return {
      headers: [
        { text: '#', value: 'id', sortable: true },
        { text: 'Domain', value: 'url', sortable: true },
        { text: 'Total', value: 'count', sortable: true },
      ],
      search: '',
      singleSelect: false,
      selected: this.items ? this.items : [],
      averages: this.avgs ? this.avgs : [],
    }
  },
  methods: {

  },
}
</script>

<style scoped>
.greater-than {
  color: #2ecc71;
}
.less-than {
  color: #ff4c51;
}
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 60px 0px 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-all;
}
</style>
