<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      class="elevation-1"
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="url"
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.anchor`]="{ item }">
        <div class="col-domain">
          {{ item.anchor }}
        </div>
      </template>
      <template v-slot:[`item.count`]="{ item }">
        {{ Number(item.count).toFixed(0) }}
      </template>
      <template v-slot:[`item.percentage`]="{ item }"> {{ Number(item.percentage).toFixed(2) }}% </template>
      <template v-slot:[`item.chart`]="{ item }">
        <div :style="`background: #ff8800; width: ${ Number(item.chart).toFixed(2) }%; height: 10px;`"></div>
      </template>
      <template v-slot:[`item.category`]="{ item }"> {{ item.category }} </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    items: Array,
    headers: Array,
  },
  data() {
    return {
      search: '',
    }
  },
}
</script>

<style scoped>
.cont-table {
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  gap: 20px;
}
.col-domain {
  max-width: 500px !important;
  word-break: break-word;
}
</style>
